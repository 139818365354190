/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, pathname }) {
  const { site, preview } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
        preview: file(relativePath: { eq: "work-thumb-preview.jpg" }) {
          ...imageSEOProp
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const imageDefault = preview.childImageSharp.resize.src
  const metaImage = image !== null ? image : imageDefault
  const imageUrl = `${site.siteMetadata.siteUrl}${metaImage}`
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const isTitleEmpty = title.trim() === "" ? site.siteMetadata.title : title
  const titleTemplate =
    title.trim() === "" ? site.siteMetadata.title : `${site.siteMetadata.title} - %s`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={isTitleEmpty}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: "og:site_name",
          content: site.siteMetadata.title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ]
        .concat(
          title.trim() !== ""
            ? [
                {
                  property: "og:title",
                  content: title,
                },
                {
                  name: "twitter:title",
                  content: title,
                },
              ]
            : [],
        )
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: imageUrl,
                },
                {
                  property: "og:image:width",
                  content: 1200,
                },
                {
                  property: "og:image:height",
                  content: 600,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ],
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: null,
  image: null,
  pathname: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
}

export default SEO
