import React from "react"
import { Global, css } from "@emotion/core"
import { toEm, fontSize, toRem, breakPoints } from "./utils"

const Resets = () => (
  <Global
    styles={css`
      :root {
        --gray-darker: hsl(240, 5%, 15%);
        --gray-dark: hsl(240, 6%, 37%);
        --gray-light: hsl(240, 10%, 40%);
        --gray-lighter: hsl(240, 11%, 60%);
        --gray-lightest: hsl(240, 30%, 95%);
        --white: #ffffff;
        --link: hsl(220, 100%, 43%);
        --base-font-size: ${`${fontSize}px`};
        --body-font-size: ${toEm(16)};
        --small-font-size: ${toEm(12)};
        --title-font-size: ${toEm(20)};
        --title-color: var(--gray-darker);
        --small-gutter: ${toRem(10)};
        --medium-gutter: ${toRem(15)};
        --large-gutter: ${toRem(30)};
        --base-font-family: "NB International Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        --title-font-family: "NB International Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      }

      html,
      body {
        -webkit-font-smoothing: antialiased;
        font: 100%/1.5 var(--base-font-family);
        text-rendering: optimizelegibility;
        color: var(--gray-dark);
        background-color: var(--white);
        margin: 0;
        padding: 0;
      }

      body {
        font-size: var(--base-font-size);
      }

      a {
        color: var(--link);
        text-decoration: none;
      }

      h1 {
        font-size: var(--title-font-size);

        @media (min-width: ${breakPoints.medium}) {
          --title-font-size: ${toEm(44)};
        }
      }

      h2 {
        font-size: var(--title-font-size);
        @media (min-width: ${breakPoints.medium}) {
          --title-font-size: ${toEm(30)};
        }
      }

      h3 {
        font-size: var(--title-font-size);

        @media (min-width: ${breakPoints.medium}) {
          --title-font-size: ${toEm(24)};
        }
      }

      h1,
      h2,
      h3 {
        color: var(--title-color);
        font-family: var(--title-font-family);
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: ${toRem(15)};
        font-weight: 600;

        @media (min-width: ${breakPoints.medium}) {
          margin-bottom: ${toRem(30)};
        }
      }

      p {
        font-size: var(--body-font-size);
        margin-top: 0;
        margin-bottom: ${toRem(15)};
        color: var(--gray-darker);
        font-weight: 300;

        @media (min-width: ${breakPoints.medium}) {
          --body-font-size: ${toEm(24)};
          margin-bottom: ${toRem(30)};
          color: var(--gray-dark);
          font-weight: 300;
        }
      }

      small {
        font-size: var(--small-font-size);

        @media (min-width: ${breakPoints.medium}) {
          --small-font-size: ${toEm(14)};
        }
      }

      ul {
        list-style-type: disc;
        list-style-position: outside;
      }

      ul li {
        margin-left: ${toRem(20)};
      }

      strong {
        font-weight: bold;
      }

      .gatsby-image-wrapper,
      .gatsby-resp-image-wrapper {
        overflow: hidden;
      }

      .gatsby-image-placeholder {
        filter: blur(20px);
        transform: scale(1.25);

        @media (min-width: ${breakPoints.medium}) {
          filter: blur(40px);
        }
      }
    `}
  />
)

export default Resets
