import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { toEm, toRem, breakPoints } from "../styles/utils"
import Container from "./container"

const HeaderWrapper = styled.header`
  padding-top: ${toRem(20)};
  padding-bottom: ${toRem(20)};
  position: var(--position);
  width: 100%;
  z-index: 10;

  @media (min-width: ${breakPoints.medium}) {
    padding-top: ${toRem(30)};
    padding-bottom: ${toRem(30)};
  }
`

const LogoBranding = styled.div`
  font-size: ${toEm(24)};
  font-weight: bold;
  margin-right: auto;
  padding-left: ${toRem(10)};

  > a {
    display: flex;
    line-height: 0;
  }

  @media (min-width: ${breakPoints.medium}) {
    padding-left: ${toRem(15)};
    font-size: ${toEm(28)};
  }
`

const NavItems = styled.nav`
  a {
    padding: ${toRem(15)};
  }

  &,
  a {
    color: var(--color);
    font-size: ${toEm(17)};
  }

  @media (min-width: ${breakPoints.medium}) {
    a {
      font-size: ${toEm(22)};
    }
  }
`

const LogoSvg = styled.svg`
  width: auto;
  height: ${toRem(30)};

  @media (min-width: ${breakPoints.medium}) {
    height: ${toRem(40)};
  }

  > path {
    fill: var(--color);
  }
`

const Header = ({ float, dark }) => (
  <HeaderWrapper dark={dark} float={float}>
    <Global
      styles={css`
        header {
          --color: ${dark ? "var(--white)" : "var(--gray-darker)"};
          --position: ${float ? "absolute" : "relative"};
        }
      `}
    />

    <Container align="center">
      <LogoBranding>
        <Link to="/" aria-label="Home Page">
          <LogoSvg viewBox="0 0 512 433" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M439.715 157.315C431.873 136.925 420.992 100.916 377.987 59.6853C327.776 11.5461 254.297 -8.20334 185.716 4.75722C109.787 18.3349 36.3079 75.1145 9.3654 161.518C-10.8414 225.704 6.30376 302.85 37.5325 331.857C73.0476 364.567 127.266 341.658 185.473 306.294C243.68 270.931 313.806 221.63 334.288 251.008C354.77 280.386 254.148 348.399 227.627 391.423C201.107 434.447 286.604 447.458 342.622 410.296C398.64 373.135 447.879 323.251 489.149 244.848C530.42 166.444 519.323 96.7582 439.715 157.315ZM120.197 251.008C102.439 254.094 85.2941 241.75 82.2325 223.852C79.1708 205.954 91.4174 188.674 109.175 185.588C126.932 182.502 144.078 194.845 147.139 212.743C150.201 231.258 137.954 247.922 120.197 251.008ZM259.195 193.6C241.438 196.686 224.293 184.342 221.231 166.444C218.169 148.547 230.416 131.266 248.174 128.18C265.931 125.094 283.076 137.437 286.138 155.335C288.587 173.233 276.953 190.514 259.195 193.6Z"
              fill="none"
            />
          </LogoSvg>
        </Link>
      </LogoBranding>
      <NavItems>
        <Link to="/about/">About</Link>
      </NavItems>
    </Container>
  </HeaderWrapper>
)

Header.defaultProps = {
  float: false,
  dark: false,
}

Header.propTypes = {
  float: PropTypes.bool,
  dark: PropTypes.bool,
}

export default Header
