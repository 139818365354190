import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { breakPoints, gutter, toRem, center, maxWidth } from "../styles/utils"

const FooterWrapper = styled.footer`
  ${center};
  ${gutter};
  margin-bottom: ${toRem(60)};
  margin-top: ${toRem(60)};
  flex-direction: row;
  align-items: center;
  max-width: ${maxWidth};

  @media (min-width: ${breakPoints.medium}) {
    display: flex;
  }
`

const CreditCopy = styled.div`
  flex: 1;
  text-align: center;

  small {
    ${gutter};
    color: var(--gray-lighter);
    display: inline-block;
    width: 70%;
  }

  a {
    color: var(--gray-lighter);
  }

  @media (min-width: ${breakPoints.medium}) {
    text-align: right;
  }
`

const SocMed = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-bottom: ${toRem(25)};

  @media (min-width: ${breakPoints.small}) {
    margin-bottom: 0;
  }

  > nav {
    ${gutter};
    display: flex;

    > a {
      --link: var(--gray-darker);
      width: ${toRem(25)};
      height: ${toRem(25)};
      margin-right: ${toRem(20)};
    }

    > a:last-child {
      margin-right: 0 !important;
    }
  }

  @media (min-width: ${breakPoints.medium}) {
    > nav > a {
      margin-right: ${toRem(15)};
    }
  }
`

const SocMedSVG = styled.svg`
  width: 100%;
  height: 100%;

  > path {
    fill: var(--gray-darker);
  }
`

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  )

  return (
    <FooterWrapper>
      <SocMed>
        <nav>
          <OutboundLink href="https://www.linkedin.com/in/oooscars" aria-label="Linkedin">
            <SocMedSVG viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0H8ZM9 9.73487C9 11.2977 10.3023 12.4697 11.7349 12.4697C13.2977 12.4697 14.4697 11.2977 14.4697 9.73487C14.4697 8.17209 13.1674 7 11.7349 7C10.1721 7 9 8.30232 9 9.73487ZM9.39079 14.5535H14.0791V29.4H9.39079V14.5535ZM31.3999 29.3999H26.7116V22.1069C26.7116 20.4139 26.7116 18.1999 24.3674 18.1999C22.0232 18.1999 21.6325 20.0232 21.6325 21.9767V29.3999H16.9441V14.5534H21.372V16.6372C22.0232 15.4651 23.4558 14.293 25.7999 14.293C30.4883 14.293 31.3999 17.4186 31.3999 21.3255V29.3999Z"
                fill="none"
              />
            </SocMedSVG>
          </OutboundLink>
          <OutboundLink href="https://dribbble.com/oooscars" aria-label="Dribbble">
            <SocMedSVG viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.3023 5.02323C29.7674 1.86046 25.1163 0 20.093 0C18.4186 0 16.9302 0.186046 15.4419 0.558137C16 1.30232 19.5349 6.13951 22.8837 12.279C29.9534 9.48833 32.9302 5.58137 33.3023 5.02323ZM0.558228 15.8139C1.86055 9.67437 5.95355 4.4651 11.5349 1.86046C12.0931 2.60464 15.6279 7.44183 18.9767 13.3953C9.67446 15.8139 1.48846 15.8139 0.558228 15.8139ZM21.7673 19.9997C21.6278 20.0462 21.4883 20.0927 21.3953 20.0927C12.5803 22.9776 7.35517 30.1426 5.60083 32.5482C5.3187 32.9351 5.12633 33.1989 5.02323 33.302C1.86046 29.7671 0 24.9299 0 19.9067V19.3486C0.0378575 19.3486 0.0901974 19.3489 0.156443 19.3493H0.15651H0.156594H0.156698H0.156829C1.7195 19.3585 11.0067 19.4132 20.465 16.5579L22.1394 19.9067C22.0464 19.9067 21.9069 19.9532 21.7673 19.9997ZM25.8605 18.6044C25.814 18.4183 25.7675 18.2323 25.6745 18.0462C25.3024 17.116 24.9303 16.1858 24.3722 15.0695C31.814 12.0928 35.1628 7.81374 35.5349 7.2556C38.3256 10.7905 40 15.0695 40 19.7207C39.8523 19.7207 39.4774 19.6694 38.9189 19.5929C36.6636 19.2843 31.4149 18.566 26.0466 19.1625C25.9536 18.9765 25.907 18.7904 25.8605 18.6044L25.8605 18.6044ZM27.3488 22.3255C29.9535 29.5813 31.0698 35.3487 31.2558 36.465C35.7209 33.4882 38.8837 28.651 39.8139 23.0697C39.7622 23.0568 39.6882 23.0367 39.593 23.0108L39.5929 23.0108C38.316 22.6646 33.2352 21.2867 27.3488 22.3255ZM7.81405 35.7208C8.18614 34.9766 12.2791 27.1627 23.4419 23.2557C26.2528 29.9316 27.4047 35.778 27.8377 37.9752C27.8632 38.1048 27.8863 38.2218 27.907 38.3254C25.4884 39.4417 22.8838 39.9998 20.0931 39.9998C15.4419 39.9998 11.1629 38.3254 7.81405 35.7208Z"
                fill="none"
              />
            </SocMedSVG>
          </OutboundLink>
          <OutboundLink href="https://www.behance.net/oooscars" aria-label="Behance">
            <SocMedSVG viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.298 18.152h-4.203v-3.796h4.068c1.762 0 2.712.542 2.712 1.898 0 1.356-1.22 1.898-2.577 1.898zM14.57 25.61h-4.475V21h4.61c1.22 0 2.576.542 2.576 2.034 0 1.763-.949 2.576-2.712 2.576zM28.129 18.288c1.085 0 2.44.678 2.576 2.712h-5.152c.135-1.763.949-2.712 2.576-2.712z"
                fill="#fff"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 8a8 8 0 00-8-8H8a8 8 0 00-8 8v24a8 8 0 008 8h24a8 8 0 008-8V8zm-25.024 3.373H6.57v17.356h7.322c3.526 0 4.475-.678 5.424-1.627.95-.814 1.492-2.17 1.492-3.526 0-1.763-.543-3.39-2.712-4.203.813-.407 2.17-1.085 2.17-3.526 0-1.763-1.085-4.474-5.289-4.474zM28.536 29c2.17 0 4.61-.95 5.56-4.068v-.135H30.57c-.271.813-1.22 1.355-2.17 1.355-2.566 0-2.825-1.942-2.96-2.955a18.725 18.725 0 00-.023-.163h8.814v-.543c0-5.83-3.39-7.05-5.966-7.05-5.56 0-6.102 5.016-6.102 6.508 0 5.288 2.712 7.051 6.373 7.051zm3.66-16.814H23.79v1.898h8.407v-1.898z"
                fill="#fff"
              />
            </SocMedSVG>
          </OutboundLink>
          <OutboundLink href="https://twitter.com/oooscars" aria-label="Twitter">
            <SocMedSVG viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.8261 12.1739C35.8261 12.5217 35.8261 12.8696 35.8261 13.2174C35.8261 24 27.6522 36.5217 12.5217 36.5217C7.82609 36.5217 3.47826 35.1304 0 32.6957C0.521739 32.8696 1.21739 32.8696 1.91304 32.8696C5.73913 32.8696 9.3913 31.6522 12.1739 29.3913C8.52174 29.2174 5.56522 26.9565 4.52174 23.6522C5.04348 23.8261 5.56522 23.8261 6.08696 23.8261C6.78261 23.8261 7.47826 23.6522 8.17391 23.4783C4.34783 22.7826 1.56522 19.4783 1.56522 15.4783C1.56522 15.3043 1.56522 15.3043 1.56522 15.3043C2.6087 16 3.82609 16.3478 5.21739 16.3478C2.95652 14.9565 1.56522 12.3478 1.56522 9.56522C1.56522 8 1.91304 6.6087 2.78261 5.56522C6.78261 10.4348 12.8696 13.7391 19.6522 14.087C19.4783 13.3913 19.4783 12.8696 19.4783 12.1739C19.4783 7.65217 23.1304 4 27.6522 4C30.087 4 32.1739 5.04348 33.7391 6.6087C35.6522 6.26087 37.3913 5.56522 38.9565 4.69565C38.2609 6.6087 37.0435 8.17391 35.3043 9.21739C36.8696 9.04348 38.6087 8.69565 40 8C38.7826 9.56522 37.3913 10.9565 35.8261 12.1739Z"
                fill="none"
              />
            </SocMedSVG>
          </OutboundLink>
        </nav>
      </SocMed>
      <CreditCopy>
        <small>
          © {new Date().getFullYear()} {site.siteMetadata.title}. Site built with{" "}
          <OutboundLink href="https://gatsbyjs.com">Gatsby</OutboundLink> and hosted by{" "}
          <OutboundLink href="https://netlify.com">Netlify</OutboundLink>.
        </small>
      </CreditCopy>
    </FooterWrapper>
  )
}

export default Footer
