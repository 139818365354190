import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Resets from "../styles/resets"

const WrapperSite = styled.div`
  position: relative;
  width: 100%;
`

const Wrapper = ({ children }) => (
  <WrapperSite>
    <Resets />
    {children}
  </WrapperSite>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
